import { debounce, isMatch } from 'lodash';

import { IWixAPI } from '@wix/native-components-infra/dist/src/types/types';
import {
  ClientSearchSDK,
  ISearchDocument,
  ISearchRequest,
  ISearchSDKDemoContentOptions,
  SearchDocumentType,
  ISearchSDKMockOptions,
} from '@wix/client-search-sdk';

import initSchemaLogger, {
  loadingFinishedParams,
  Logger,
} from '@wix/bi-logger-wix-search-widget';
import {
  Settings,
  CategorySettings,
} from '@wix/search-results-settings-definitions';
import { isEditorX } from '@wix/search-shared-libs';

import { ITEM_TYPES } from '@wix/advanced-seo-utils/api';
import { IWidgetControllerConfig } from '../platform.types';
import { ISearchLocation } from '../location';
import { getTotalPages } from '../pagination';
import { SearchRequestStatus } from '../../types/types';
import {
  createBICorrelationId,
  BICorrelationIdKey,
  BICorrelationIdStore,
  getBIDefaults,
  getBITotals,
  getAbsoluteDocumentIndex,
} from '../bi';
import { APP_DEFINITION_ID, WIDGET_ID } from '../../lib/constants';

import { IFedopsLogger } from '../types';
import {
  ISearchSortConfig,
  ISeoItemData,
  SearchResultsControllerStoreState,
} from './SearchResultsControllerStore.types';
import {
  getSortOptions,
  getSortRequest,
  getDefaultSortOption,
  isOrderingSupported,
  SortOptionId,
} from '../sort/getSortOptions';
import { search } from '../search';
import { createSearchRequestBI } from '../bi/createSearchRequestBI';
import { equalSearchRequests } from './equalSearchRequests';
import { getVisibleCategories } from './getVisibleCategories';
import { withDocumentType } from './withDocumentType';
import {
  fallbackFeatureToggles,
  FeatureToggles,
} from '../../lib/experiments/experiments';

export class SearchResultsControllerStore {
  private readonly wixCodeApi: IWixAPI;
  private readonly setComponentProps: (
    props: SearchResultsControllerStoreState,
  ) => void;

  private readonly fedopsLogger: IFedopsLogger | undefined;
  private readonly biLogger: Logger;
  private readonly biCorrelationIdStore: BICorrelationIdStore;
  private readonly reportError: (error: Error) => void;
  private readonly searchSDK: ClientSearchSDK;
  private readonly searchLocation: ISearchLocation;
  private readonly _featureTogglesPromise: Promise<FeatureToggles>;
  private readonly buildSearchAllUrl: IWidgetControllerConfig['buildSearchAllUrl'];

  private demoContentOptions: ISearchSDKDemoContentOptions;
  private documentTypes: SearchDocumentType[];
  private featureToggles = fallbackFeatureToggles;
  private state: SearchResultsControllerStoreState;

  constructor(
    {
      appParams,
      buildSearchAllUrl,
      featureToggles,
      platformAPIs,
      reportError,
      searchLocation,
      searchSDK,
      setProps,
      siteLanguage,
      wixCodeApi,
    }: IWidgetControllerConfig,
    settings: Settings,
  ) {
    this.wixCodeApi = wixCodeApi;
    this.setComponentProps = setProps;
    this.reportError = err =>
      reportError(err, {
        tags: {
          ssr: this.state.isSSR,
          fluid: this.state.isFluid,
          mobile: this.state.isMobile,
          demo: this.state.isDemoContent,
        },
      });
    this.searchSDK = searchSDK;
    this.searchLocation = searchLocation;
    this.documentTypes = [];
    this._featureTogglesPromise = featureToggles;
    this.buildSearchAllUrl = buildSearchAllUrl;

    const staticsBaseUrl = appParams.baseUrls.staticsBaseUrl;

    this.state = {
      ...this.getEmptyResponseStateProps(),
      apiErrorDetails: undefined,
      language: siteLanguage,
      staticsBaseUrl,
      searchResultsAbsoluteUrl: '',
      settings,
      searchRequest: this.getSearchRequestFromLocationPath(
        wixCodeApi.location.path,
        settings.itemsPerPage,
      ),
      searchRequestStatus: SearchRequestStatus.Initial,
      documentTypes: [],
      onDocumentTypeChange: this.handleDocumentTypeChange,
      onQuerySubmit: this.handleQuerySubmit,
      onPageChange: this.handlePageChange,
      onDocumentClick: this.handleDocumentClick,
      onAppLoaded: this.handleAppLoaded,
      // TODO: cleanup when resolved https://github.com/wix-private/native-components-infra/pull/28
      viewMode: wixCodeApi.window.viewMode,
      isDemoContent: wixCodeApi.window.viewMode !== 'Site',
      isMobile: wixCodeApi.window.formFactor === 'Mobile',
      sortConfig: {
        onSortChange: this.onSortChange,
        selectedSortOption: 0,
        showSort: false,
        sortOptions: [],
      },
      reportError: this.reportError,
      isFluid: false,
      isSSR: wixCodeApi.window.rendering.env === 'backend',
    };

    // this.initSettingsEventHandler(appPublicData);

    if (this.state.isDemoContent) {
      this.setDemoContentOptions({
        shouldMockDocumentTypes: false,
        shouldHaveSearchResults: true,
      });
    }

    // NOTE: http://wixplorer.wixpress.com/out-of-iframe/guides/Reference/Fedops
    this.fedopsLogger = platformAPIs.fedOpsLoggerFactory?.getLoggerForWidget({
      appId: APP_DEFINITION_ID,
      widgetId: WIDGET_ID,
    });

    // NOTE: editor version of biLoggerFactory does not add some required defaults,
    // so set it manually
    this.biLogger = initSchemaLogger(platformAPIs.biLoggerFactory?.())({
      defaults: getBIDefaults(platformAPIs, wixCodeApi.window.viewMode),
    });

    this.biCorrelationIdStore = new BICorrelationIdStore(
      platformAPIs,
      this.reportError,
    );

    wixCodeApi.location.onChange(({ path }) => {
      const stateSearchRequest = this.state.searchRequest;
      const locationSearchRequest = this.getSearchRequestFromLocationPath(
        path,
        stateSearchRequest.paging.pageSize,
      );

      if (equalSearchRequests(locationSearchRequest, stateSearchRequest)) {
        return;
      }

      void this.changeSearchRequest(locationSearchRequest);
    });
  }

  private getSearchRequestFromLocationPath(
    path: string[],
    pageSize: number,
  ): ISearchRequest {
    const locationSearchRequest = this.searchLocation.decodePath(
      /**
       * first item in location path -- is "page name"
       * we should decode all parts after
       */
      path.slice(1).join('/'),
    );

    return this.searchLocation.toSDKSearchRequest(
      locationSearchRequest,
      pageSize,
    );
  }

  private isSSR() {
    return this.wixCodeApi.window.rendering.env === 'backend';
  }

  private setDemoContentOptions(partialOptions: ISearchSDKMockOptions) {
    if (
      this.demoContentOptions &&
      isMatch(this.demoContentOptions, partialOptions)
    ) {
      return;
    }

    this.demoContentOptions = {
      ...this.demoContentOptions,
      ...partialOptions,
    };
    this.searchSDK.useDemoContent(this.demoContentOptions);
  }

  private setState(partialState: Partial<SearchResultsControllerStoreState>) {
    this.state = {
      ...this.state,
      ...partialState,
    };

    this.setComponentProps(this.state);
  }

  private isFluid() {
    return isEditorX();
  }

  private getSearchSubmitCorrelationId(): string | undefined {
    const key = BICorrelationIdKey.SearchSubmit;
    return this.biCorrelationIdStore.has(key)
      ? this.biCorrelationIdStore.get(key)
      : undefined;
  }

  private async search(
    searchRequest: ISearchRequest,
    visibleCategories: Array<[string, CategorySettings]>,
  ) {
    searchRequest = this.withOrdering(
      withDocumentType(searchRequest, visibleCategories),
    );

    try {
      const searchResult = await search({
        searchRequest,
        searchSDK: this.searchSDK,
        previousQuery: this.state.previousQuery,
        isMobile: this.state.isMobile,
        previousTotals: this.state.searchResponseTotals,
        searchResultsAbsoluteUrl: this.state.searchResultsAbsoluteUrl,
        searchLocation: this.searchLocation,
        visibleCategories,
        bi: createSearchRequestBI({
          biLogger: this.biLogger,
          isDemoContent: this.state.isDemoContent,
          isEnabled: !this.isSSR(),
          searchRequest,
          correlationId: this.getSearchSubmitCorrelationId(),
        }),
      });

      if ('isError' in searchResult) {
        return {
          ...this.getFailedQueryStateProps(),
          apiErrorDetails: searchResult.errorDetails,
        };
      }

      const {
        searchResponse,
        searchResponseTotals,
        searchSamples,
      } = searchResult;

      return {
        apiErrorDetails: undefined,
        searchRequest,
        searchResponse,
        searchResponseTotals,
        searchSamples,
        searchRequestStatus: SearchRequestStatus.Loaded,
        previousQuery: searchRequest.query,
        sortConfig: this.getUpdatedSortConfig(
          searchRequest.documentType,
          searchResponse.totalResults,
        ),
      };
    } catch (error) {
      this.reportError(error);

      return {
        ...this.getFailedQueryStateProps(),
        error: JSON.stringify(error),
      };
    }
  }

  private async changeSearchRequest(
    searchRequestRaw: ISearchRequest,
  ): Promise<void> {
    this.setState({
      searchRequestStatus: SearchRequestStatus.Loading,
    });

    const visibleCategories = getVisibleCategories(
      this.state.settings,
      this.documentTypes,
    );

    try {
      const partialState = await this.search(
        searchRequestRaw,
        visibleCategories,
      );
      this.setState(partialState);
    } catch (error) {
      this.setState({
        ...this.getFailedQueryStateProps(),
        error: JSON.stringify(error),
      });
      this.reportError(error);
    }
  }

  private readonly changeSearchRequestLazy: (
    request: ISearchRequest,
  ) => void = debounce(this.changeSearchRequest, 500);

  async getLanguage() {
    let response: Response;
    try {
      response = await fetch(
        `${this.state.staticsBaseUrl}assets/locales/messages_${this.state.language}.json`,
      );
      return await response.json();
    } catch (e) {
      response = await fetch(
        `${this.state.staticsBaseUrl}assets/locales/messages_en.json`,
      );
      return response.json();
    }
  }

  updateSettings(settings: Settings) {
    const prevSettings = this.state.settings;

    this.setState({
      settings,
    });

    if (prevSettings.itemsPerPage !== settings.itemsPerPage) {
      this.changeSearchRequestLazy({
        ...this.state.searchRequest,
        paging: {
          page: 1,
          pageSize: settings.itemsPerPage,
        },
      });
    }

    // https://github.com/wix-private/site-search/issues/153
    // this.settingsEventHandler.updateData(appPublicData);
  }

  getSearchSDK(): ClientSearchSDK {
    return this.searchSDK;
  }

  private applySearchRequest(searchRequest: ISearchRequest) {
    if (
      this.state.isDemoContent ||
      equalSearchRequests(this.state.searchRequest, searchRequest)
    ) {
      void this.changeSearchRequest(searchRequest);
      return;
    }

    void this.searchLocation.navigateToSearchResults(
      this.searchLocation.toLocationSearchRequest(searchRequest),
    );
  }

  changeDocumentType = (documentType: SearchDocumentType) => {
    const searchRequest = this.state.searchRequest;

    this.setState({
      sortConfig: {
        ...this.state.sortConfig,
        selectedSortOption: getDefaultSortOption(),
      },
    });

    this.applySearchRequest({
      ...searchRequest,
      documentType,
      paging: {
        ...searchRequest.paging,
        page: 1,
      },
    });
  };

  changeQuery = (query: string) => {
    const searchRequest = this.state.searchRequest;

    this.applySearchRequest({
      ...searchRequest,
      query,
      paging: {
        ...searchRequest.paging,
        page: 1,
      },
    });
  };

  changePage = (page: number) => {
    const searchRequest = this.state.searchRequest;

    this.applySearchRequest({
      ...searchRequest,
      paging: {
        ...searchRequest.paging,
        page,
      },
    });
  };

  updateDemoMode(data: {
    shouldHaveSearchResults: boolean;
    shouldSetNonAllDocumentType: boolean;
  }) {
    const { shouldHaveSearchResults, shouldSetNonAllDocumentType } = data;

    let searchRequest = this.state.searchRequest;
    let isSearchRequestChanged = false;
    let isDemoContentOptionsChanged = false;

    if (
      shouldHaveSearchResults !==
      this.demoContentOptions.shouldHaveSearchResults
    ) {
      this.setDemoContentOptions({
        shouldHaveSearchResults,
      });

      isDemoContentOptionsChanged = true;
    }

    if (shouldSetNonAllDocumentType) {
      const documentTypeSource = this.state.documentTypes.find(
        ({ documentType }) => documentType !== SearchDocumentType.All,
      );

      if (
        documentTypeSource &&
        documentTypeSource.documentType !== searchRequest.documentType
      ) {
        searchRequest = {
          ...searchRequest,
          documentType: documentTypeSource.documentType,
        };

        isSearchRequestChanged = true;
      }
    }

    if (isDemoContentOptionsChanged || isSearchRequestChanged) {
      this.applySearchRequest(searchRequest);
    }
  }

  private readonly handleAppLoadStarted = () => {
    this.fedopsLogger?.appLoadStarted();
  };

  private readonly handleAppLoaded = () => {
    this.fedopsLogger?.appLoaded();
  };

  private readonly handleDocumentTypeChange = (
    documentType: SearchDocumentType,
    biParams?: { source: string },
  ): void => {
    void this.biLogger.documentTypeChange({
      isDemo: this.state.isDemoContent,
      target: this.state.searchRequest.query,
      correlationId: this.getSearchSubmitCorrelationId(),
      tabName: documentType,
      ...biParams,
    });

    this.changeDocumentType(documentType);
  };

  private readonly handleQuerySubmit = (query: string): void => {
    const correlationId = createBICorrelationId();

    this.biCorrelationIdStore.set(
      BICorrelationIdKey.SearchSubmit,
      correlationId,
    );

    // TODO: add clickOrigin
    void this.biLogger.searchSubmit({
      isDemo: this.state.isDemoContent,
      target: query,
      correlationId,
    });

    this.changeQuery(query);
  };

  private readonly handlePageChange = (page: number) => {
    // const correlationId = this.biCorrelationIdStore.get(
    //   BICorrelationIdKey.SearchSubmit,
    // );

    // TODO: uncomment, when need `pageChange` event.
    // void this.biLogger.pageChange({
    //   isDemo: this.state.isDemoContent,
    //   target: page,
    //   correlationId,
    // });

    this.changePage(page);
  };

  private readonly handleDocumentClick = (
    document: ISearchDocument,
    index: number,
  ): void => {
    const { isDemoContent, searchRequest, searchResponseTotals } = this.state;

    // 99:305 searchResults.results.click
    // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/305?artifactId=com.wixpress.wix-search-widget
    void this.biLogger.documentClick({
      correlationId: this.getSearchSubmitCorrelationId(),
      documentId: document.id,
      documentType: document.documentType,
      isDemo: isDemoContent,
      pageUrl: document.url,
      resultsArray: getBITotals(searchResponseTotals),
      searchIndex: getAbsoluteDocumentIndex(searchRequest.paging, index),
      target: this.state.searchRequest.query,
      resultClicked: document.title,
    });

    this.wixCodeApi.location.to?.(document.relativeUrl);
  };

  private readonly onSortChange = (selectedSortOption: SortOptionId) => {
    const { settings } = this.state;
    if (selectedSortOption !== this.state.sortConfig.selectedSortOption) {
      this.setState({
        sortConfig: {
          ...this.state.sortConfig,
          selectedSortOption,
        },
      });
      void this.changeSearchRequest({
        ...this.state.searchRequest,
        paging: {
          page: 1,
          pageSize: settings.itemsPerPage,
        },
      });
    }
  };

  shouldShowSort(
    documentType: SearchDocumentType | undefined,
    resultsCount: number,
  ): boolean {
    return (
      !!documentType &&
      resultsCount > 0 &&
      isOrderingSupported(documentType, this.featureToggles)
    );
  }

  getUpdatedSortConfig(
    documentType: SearchDocumentType | undefined,
    resultsCount: number,
  ): ISearchSortConfig {
    return {
      ...this.state.sortConfig,
      showSort: this.shouldShowSort(documentType, resultsCount),
      sortOptions: getSortOptions(documentType),
    };
  }

  withOrdering(searchRequest: ISearchRequest) {
    return {
      ...searchRequest,
      ordering: getSortRequest(this.state.sortConfig.selectedSortOption),
    };
  }

  getEmptyResponseStateProps() {
    return {
      searchResponseTotals: {},
      searchSamples: [],
      searchResponse: {
        documents: [],
        totalResults: 0,
      },
    };
  }

  getFailedQueryStateProps(): Partial<SearchResultsControllerStoreState> {
    return {
      ...this.getEmptyResponseStateProps(),
      apiErrorDetails: undefined,
      error: undefined,
      previousQuery: undefined,
      searchRequestStatus: SearchRequestStatus.Failed,
    };
  }

  logBiLoadingStarted(p: loadingFinishedParams = {}): void {
    if (this.isSSR()) {
      return;
    }
    // 99:301 searchResults.loading.started
    // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/301?artifactId=com.wixpress.wix-search-widget
    void this.biLogger.loadingStarted(p);
  }

  logBiLoadingFinished(p: loadingFinishedParams): void {
    if (this.isSSR()) {
      return;
    }
    // 99:302 searchResults.loading.finished
    // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/302?artifactId=com.wixpress.wix-search-widget
    void this.biLogger.loadingFinished(p);
  }

  redirectOnInvalidPageRequest() {
    const { searchRequest, searchResponse } = this.state;
    const { pageSize, page } = searchRequest.paging;
    const totalPages = getTotalPages(pageSize, searchResponse.totalResults);

    if (page > totalPages) {
      this.applySearchRequest({
        ...searchRequest,
        paging: {
          ...searchRequest.paging,
          page: 1,
        },
      });
    }
  }

  async renderSeoTags() {
    if (!this.featureToggles.renderSeoTagsEnabled) {
      return;
    }

    const resultType = this.state.searchRequest.documentType;
    const searchTerm = this.state.searchRequest.query;
    const documents =
      resultType === SearchDocumentType.All
        ? this.state.searchSamples
        : this.state.searchResponse.documents;
    const itemData: ISeoItemData = {
      allResultsUrl: await this.buildSearchAllUrl(searchTerm),
      documents,
      pageUrl: this.wixCodeApi.location.url,
      resultsTotal: this.state.searchResponse.totalResults,
      resultType,
      searchTerm,
    };

    this.wixCodeApi.seo.renderSEOTags({
      itemType: ITEM_TYPES.SEARCH_PAGE,
      itemData,
    });
  }

  async setInitialState(): Promise<void> {
    this.handleAppLoadStarted();

    if (!this.biCorrelationIdStore.has(BICorrelationIdKey.SearchSubmit)) {
      // NOTE: there are no correlationId from previous submit, so create new one
      this.biCorrelationIdStore.set(
        BICorrelationIdKey.SearchSubmit,
        createBICorrelationId(),
      );
    }

    const startTime = Date.now();

    this.logBiLoadingStarted();

    try {
      const composedPromise = Promise.all([
        this.searchLocation.getSearchResultsAbsoluteUrl(),
        this.searchSDK.getDocumentTypes(),
        this._featureTogglesPromise,
      ]);

      const translations = await this.getLanguage();

      this.setState({
        translations,
      });

      const [
        searchResultsAbsoluteUrl,
        documentTypes,
        featureToggles,
      ] = await composedPromise;

      this.featureToggles = featureToggles;

      this.documentTypes = documentTypes.map(t => t.documentType);

      const isFluid = this.isFluid();
      let isMobile = this.state.isMobile;

      if (isFluid) {
        isMobile = false;
      }

      if (this.state.isDemoContent) {
        this.setDemoContentOptions({
          documentTypesForSearchResults: this.documentTypes,
        });
      }

      const visibleCategories = getVisibleCategories(
        this.state.settings,
        this.documentTypes,
      );

      const partialState = await this.search(
        this.state.searchRequest,
        visibleCategories,
      );

      this.setState({
        searchResultsAbsoluteUrl,
        documentTypes,
        isFluid,
        isMobile,
        ...partialState,
      });

      this.logBiLoadingFinished({
        loadingDuration: Date.now() - startTime,
        success: true,
      });

      this.redirectOnInvalidPageRequest();
    } catch (error) {
      this.setState({
        ...this.getFailedQueryStateProps(),
        error: JSON.stringify(error),
      });

      this.logBiLoadingFinished({
        error: error.toString(),
        loadingDuration: Date.now() - startTime,
        success: false,
      });

      this.reportError(error);
    }

    try {
      await this.renderSeoTags();
    } catch (error) {
      this.reportError(error);
    }

    // NOTE: on CSR appLoaded is called from componentDidMount
    if (this.isSSR()) {
      this.handleAppLoaded();
    }
  }
}
